<template>
  <v-main>
    <div>
      <v-btn
        class="ma-5"
        @click="type = 'two'"
        :color="type == 'two' ? 'warning' : ''"
        >twoPanel</v-btn
      >
      <v-btn
        class="ma-5"
        @click="type = 'four'"
        :color="type == 'four' ? 'warning' : ''"
        >fourPanel</v-btn
      >
      <v-btn
        class="ma-5"
        @click="type = 'three'"
        :color="type == 'three' ? 'warning' : ''"
        >threePanel</v-btn
      >
    </div>
    <two-panel-page v-if="type == 'two'"></two-panel-page>
    <four-panel-page v-else-if="type == 'four'"></four-panel-page>
    <three-panel-page v-else></three-panel-page>
  </v-main>
</template>

<script>
import TwoPanelPage from './TwoPanelPage.vue';
import FourPanelPage from './FourPanelPage.vue';
import ThreePanelPage from './ThreePanelPage.vue';

export default {
  components: {
    TwoPanelPage,
    FourPanelPage,
    ThreePanelPage,
  },
  data() {
    return {
      type: 'two',
    };
  },
};
</script>

<style lang="scss" scoped></style>
