<template>
  <div>
    <three-panel
      :topPanelTitle="'권한 목록'"
      :leftPanelTitle="'기본 정보'"
      :rightPanelTitle="'권한 정보'"
    >
      <template #topPanel>
        <div class="panel-body"></div>
      </template>
      <template #leftPanelBtn>
        <input type="button" value="등록" class="bt-line24 cr-orange" />
      </template>
      <template #leftPanel>
        <div class="panel-body" style="height:330px"></div>
      </template>
      <template #rightPanelBtn>
        <input type="button" value="등록" class="bt-line24 cr-orange" />
      </template>
      <template #rightPanel>
        <div class="panel-body" style="height:330px"></div>
      </template>
    </three-panel>
  </div>
</template>

<script>
import ThreePanel from '@/layouts/ThreePanel.vue';
export default {
  components: {
    ThreePanel,
  },
};
</script>

<style lang="scss" scoped></style>
