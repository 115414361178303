<template>
  <section>
    <!-- 상단 패널 -->
    <div class="panel-contents">
      <div class="col-default">
        <div class="panel">
          <h4 class="panel-top-title">{{ topPanelTitle }}</h4>
          <slot name="topPanel" />
        </div>
      </div>
    </div>
    <!-- 하단 패널 -->
    <div class="panel-contents">
      <!-- 하단 좌측 패널 -->
      <div class="col-424">
        <div class="panel">
          <h4 class="panel-top-title">{{ leftPanelTitle }}</h4>
          <p class="panel-top-btns">
            <slot name="leftPanelBtn" />
          </p>
          <slot name="leftPanel" />
        </div>
      </div>
      <!-- 하단 우측 패널 -->
      <div class="col-963">
        <div class="panel">
          <h4 class="panel-top-title">{{ rightPanelTitle }}</h4>
          <div style="height: 1px" />
          <p class="panel-top-btns">
            <slot name="rightPanelBtn" />
          </p>
          <slot name="rightPanel" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    topPanelTitle: {
      type: String,
      required: true,
    },
    leftPanelTitle: {
      type: String,
      required: true,
    },
    rightPanelTitle: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.mdm-main-wrap {
  height: 855px;
}
</style>
