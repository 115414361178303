import axios from 'axios';
import store from '@/store/index';
import { handleError } from './common/common.js';

/**
 * 메뉴 목록 조회
 * @returns
 */
async function getManagementList(params) {
  try {
    const settingHeaders = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.state.auth.token}`,
      },
    };
    return await axios.post(
      `${process.env.VUE_APP_URL_MAS_API}/service/management/list`,
      params,
      settingHeaders,
    );
  } catch (error) {
    handleError(error);
  }
}

export { getManagementList };
