<template>
  <four-panel
    :topLeftPanelWidth="fourPanel.topLeftPanelWidth"
    :topRightPanelWidth="fourPanel.topRightPanelWidth"
    :bottomLeftPanelWidth="fourPanel.topLeftPanelWidth"
    :bottomRightPanelWidth="fourPanel.topRightPanelWidth"
    :topLeftPanelTitle="'위 왼쪽 제목'"
    :topRightPanelTitle="'위 오른쪽 제목'"
    :bottomLeftPanelTitle="'아래 왼쪽 제목'"
    :bottomRightPanelTitle="'아래 오른쪽 제목'"
  >
    <template #topLeftPanel>
      <!-- <h4 class="panel-top-title">서비스 목록</h4> -->
      <!-- Table Component -->
      <table-list-component
        :tableHeight="'231'"
        :headers="headers"
        :items="itemsWithIndex"
        :nowPerPage="nowPerPage"
        :totalRecords="totalRecords"
        :totalPages="totalPages"
        :searchOpts="srvc"
        :customSlotInfo="customSlotInfo"
        :loading="loading"
        @rowClick="rowClick"
        @getDataFromApi="getMngList"
      >
        <!-------------- 검색 옵션 -------------->
        <template #searchOptions>
          <label for="search_srvcId" class="sort-label">· 서비스 아이디</label>
          <input
            v-model="srvc.srvcId"
            type="text"
            class="sort-input-text"
            maxlength="20"
            dataType="LITERAL"
            @keyup.enter="keyUpEnter"
          />
          <label for="search_srvcNm" class="sort-label">· 서비스 명</label>
          <input
            v-model="srvc.srvcNm"
            type="text"
            class="sort-input-text"
            maxlength="30"
            dataType="LITERAL"
            @keyup.enter="keyUpEnter"
          />
          <label for="search_srvcSttus" class="sort-label">· 상태 </label>
          <select
            v-model="srvc.srvcSttus"
            class="sort-input-text"
            style="width:100px;"
          >
            <option value="">전체</option>
            <option value="1">승인요청</option>
            <option value="2">승인완료</option>
            <option value="3">승인거절</option>
          </select>
        </template>
        <!-------------- subHeaderItem -------------->
        <template #subHeaderItem>
          <input
            type="button"
            value="등록"
            id="createButton"
            class="bt-line24 cr-orange"
            @click="openCreatePopup"
          />
        </template>
        <!-- customSlot -->
        <template v-slot:srvcSttus_custom="{ item }">
          <td class="text-center" :style="{ color: getColor(item.srvcSttus) }">
            {{ item.srvcSttus }}
          </td>
        </template>
        <!-- 길이 초과될 거 같은 컬럼의 경우, mouseOver 시 텍스트 표시 
              1. td-ellipsis 클래스 추가
              2. title 추가..
          -->
        <template v-slot:srvcNm_custom="{ item }">
          <td class="text-center td-ellipsis" :title="item.srvcNm">
            {{ item.srvcNm }}
          </td>
        </template>
      </table-list-component>
    </template>
    <template #topRightPanel>
      <!-- <h4 class="panel-top-title">제목 테스트</h4> -->
      <table-tab-component
        :tabItems="tabItems1"
        :tabOverlay="tabOverlay"
        :tableHeight="'336'"
      >
      </table-tab-component>
    </template>
    <template #bottomLeftPanel>
      <!-- <h4 class="panel-top-title">제목 3</h4> -->
      <!-- Table Component -->
      <table-list-component
        :tableHeight="'231'"
        :headers="headers"
        :items="itemsWithIndex"
        :nowPerPage="nowPerPage"
        :totalRecords="totalRecords"
        :totalPages="totalPages"
        :searchOpts="srvc"
        :customSlotInfo="customSlotInfo"
        :loading="loading"
        @rowClick="rowClick"
        @getDataFromApi="getMngList"
      >
        <!-------------- 검색 옵션 -------------->
        <template #searchOptions>
          <label for="search_srvcId" class="sort-label">· 서비스 아이디</label>
          <input
            v-model="srvc.srvcId"
            type="text"
            class="sort-input-text"
            maxlength="20"
            dataType="LITERAL"
            @keyup.enter="keyUpEnter"
          />
          <label for="search_srvcNm" class="sort-label">· 서비스 명</label>
          <input
            v-model="srvc.srvcNm"
            type="text"
            class="sort-input-text"
            maxlength="30"
            dataType="LITERAL"
            @keyup.enter="keyUpEnter"
          />
          <label for="search_srvcSttus" class="sort-label">· 상태 </label>
          <select
            v-model="srvc.srvcSttus"
            class="sort-input-text"
            style="width:100px;"
          >
            <option value="">전체</option>
            <option value="1">승인요청</option>
            <option value="2">승인완료</option>
            <option value="3">승인거절</option>
          </select>
        </template>
        <!-------------- subHeaderItem -------------->
        <template #subHeaderItem>
          <input
            type="button"
            value="등록"
            id="createButton"
            class="bt-line24 cr-orange"
            @click="openCreatePopup"
          />
        </template>
        <!-- customSlot -->
        <template v-slot:srvcSttus_custom="{ item }">
          <td class="text-center" :style="{ color: getColor(item.srvcSttus) }">
            {{ item.srvcSttus }}
          </td>
        </template>
        <!-- 길이 초과될 거 같은 컬럼의 경우, mouseOver 시 텍스트 표시 
              1. td-ellipsis 클래스 추가
              2. title 추가..
          -->
        <template v-slot:srvcNm_custom="{ item }">
          <td class="text-center td-ellipsis" :title="item.srvcNm">
            {{ item.srvcNm }}
          </td>
        </template>
      </table-list-component>
    </template>
    <template #bottomRightPanel>
      <!-- <h4 class="panel-top-title">제목 4</h4> -->
      <table-tab-component
        :tabItems="tabItems2"
        :tabOverlay="tabOverlay"
        :tableHeight="'336'"
      >
      </table-tab-component>
    </template>
  </four-panel>
</template>

<script>
import FourPanel from '@/layouts/FourPanel.vue';
import TableListComponent from '@/components/TableListComponent';
import TableTabComponent from '@/components/TableTabComponent';
import mixins from '@/mixins/CommonMixins';
import { getManagementList } from '@/api/testApi';

export default {
  mixins: [mixins],
  components: {
    FourPanel,
    TableListComponent,
    TableTabComponent,
  },
  data() {
    return {
      loading: false,
      // panel width 값
      fourPanel: {
        topLeftPanelWidth: '1050',
        topRightPanelWidth: '337',
        bottomLeftPanelWidth: '1050',
        bottomRightPanelWidth: '337',
      },
      // -- tab 관련 -- //
      tabOverlay: false,
      tabItems1: [
        {
          id: 'serviceInfo1',
          headers: [{ text: '회원 아이디' }, { text: '회원 명' }],
          selectedItem: {
            srvcId: {
              name: '서비스 아이디',
              value: '',
            },
            clientSecret: {
              name: '클라이언트 시크릿',
              value: '',
            },
            srvcNm: {
              name: '서비스명',
              value: '',
            },
            webServerRedirectUri: {
              name: '리다이렉트 URI',
              value: '',
            },
            mberRegistNtcnUrl: {
              name: '회원등록알람 URI',
              value: '',
            },
            srvcConfmNtcnUrl: {
              name: '서비스승인알람 URI',
              value: '',
            },
            cmpnyNm: {
              name: '회사명',
              value: '',
            },
            chargerTelno: {
              name: '관리 전화번호',
              value: '',
            },
            chargerEmail: {
              name: '관리 이메일',
              value: '',
            },
            dc: {
              name: '설명',
              value: '',
            },
            grantTypes: {
              name: '인증타입',
              value: '',
            },
            createDate: {
              name: '등록일시',
              value: '',
            },
          },
        },
      ],
      tabItems2: [
        {
          id: 'userList2',
          title: '회원 목록',
          headers: [{ text: '회원 아이디' }, { text: '회원 명' }],
          selectedItem: {},
        },
        {
          id: 'analEnv2',
          title: '분석 환경',
          headers: [{ text: '환경 아이디' }, { text: '등록 일시' }],
          selectedItem: {},
        },
      ],
      headers: [
        {
          text: '서비스 아이디',
          value: 'srvcId',
          width: '28%',
        },
        { text: '서비스 명', value: 'srvcNm', width: '28%' },
        { text: '상태', value: 'srvcSttus', width: '11%' },
        { text: '등록일시', value: 'createDate', width: '28%' },
      ],
      // --- 서비스 관리 검색 정보 --- //
      srvc: {
        srvcId: '',
        srvcNm: '',
        srvcSttus: '',
        // sidx: null,
        // sord: null,
      },
      // --- Total row 개수 --- //
      totalRecords: 0,
      totalPages: 0,
      tableItems: [],
      nowPerPage: 30,
      // --- custom Slot --- //
      customSlotInfo: [
        { name: 'srvcSttus', slotName: 'item.srvcSttus' },
        { name: 'srvcNm', slotName: 'item.srvcNm' },
      ],
    };
  },
  methods: {
    // 목록 조회
    async getMngList(searchParams) {
      this.loading = true;
      const {
        rows,
        page,
        sord,
        sidx,
        srvcNm,
        srvcId,
        srvcSttus,
      } = searchParams;
      const params = {
        // 리스트 표출건수
        rows,
        // 요청페이지
        page,
        // 정렬 컬럼
        sidx,
        // 정렬
        sord,
        srvcNm: srvcNm.trim(),
        srvcId: srvcId.trim(),
        srvcSttus: srvcSttus,
      };
      await getManagementList(params)
        .then(res => {
          const { rows, totalRecords, totalPages } = res.data;
          this.tableItems = rows;
          this.totalRecords = totalRecords;
          this.totalPages = totalPages;
        })
        .catch(error => {
          console.log('error', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 상세 조회 (row 클릭)
    rowClick(item) {
      this.tabOverlay = true;
      const keys = Object.keys(this.tabItems1[0].selectedItem);
      keys.forEach(key => {
        this.tabItems1[0].selectedItem[key].value = item[key];
      });
      this.tabOverlay = false;
    },
    getAuthType(authType) {
      if (authType == null) {
        return '없음';
      }
    },
    keyUpEnter() {
      this.$refs.tableListComponent.search();
    },
    getColor(srvcSttus) {
      if (srvcSttus === '승인완료') return '#38A1D1';
      else if (srvcSttus === '승인요청') return '#333333';
      else return '#F25555';
    },
    openCreatePopup() {
      alert('등록팝업');
    },
  },
  computed: {
    itemsWithIndex() {
      return this.tableItems.map((tableItems, index) => ({
        ...tableItems,
        srvcSttus:
          this.tableItems[index].srvcSttus == '1'
            ? '승인요청'
            : this.tableItems[index].srvcSttus == '2'
            ? '승인완료'
            : this.tableItems[index].srvcSttus == '3'
            ? '승인거절'
            : this.tableItems[index].srvcSttus == null,
        createDate: this.formatDate(
          new Date(this.tableItems[index].createDate),
          'yyyy/MM/dd hh:mm:ss',
        ),
      }));
    },
  },
};
</script>

<style lang="scss" scoped></style>
